<template>
  <div class="followup-detail">
    <el-card class="detail-card" >
      <el-scrollbar style="height: 100%">
        <comp-part title="起搏器常规随访" :margin-bottom="40">
          <div class="part part-1">
            <el-descriptions :column="4">
              <el-descriptions-item label="随访周期">{{(userInfo.periodStatus === 0 || userInfo.periodStatus === 2) ? '到院随访' : userInfo.periodText}}</el-descriptions-item>
              <el-descriptions-item label="随访日期">
                {{((userInfo.periodStatus === 0 || userInfo.periodStatus === 2)
                  ? formatDate(new Date().getTime())
                  : formatDate(userInfo.visitStartTime) + '~' +  formatDate(userInfo.visitEndTime))}}
              </el-descriptions-item>
              <el-descriptions-item label="随访状态">
<!--                <span v-if="userInfo.periodStatus === 0">未开始</span>-->
<!--                <span v-if="userInfo.periodStatus === 1">进行中</span>-->
                <span>进行中</span>
<!--                <span v-if="userInfo.periodStatus === 2">已完成</span>-->
<!--                <span v-if="userInfo.periodStatus === 3">已超窗</span>-->
              </el-descriptions-item>
              <el-descriptions-item label="所属医院">{{userInfo.hospitalName}}</el-descriptions-item>
            </el-descriptions>
          </div>
        </comp-part>
        <comp-part title="患者信息" :margin-bottom="40">
          <div class="part part-1">
            <div class="part-item">
              <div class="label">姓名：</div>
              <div class="value">{{userInfo.name}}</div>
            </div>
            <div class="part-item">
              <div class="label">性别：</div>
              <div class="value">{{userInfo.gender === 1 ? '男' : '女'}}</div>
            </div>
            <div class="part-item">
              <div class="label">病案号：</div>
              <div class="value">{{userInfo.recordNo}}</div>
            </div>
            <div class="part-item">
              <div class="label">植入日期：</div>
              <div class="value">{{formatDate(userInfo.implTime)}}</div>
            </div>
            <div class="part-item">
<!--              <el-button size="mini" class="patient-info__btn" type="primary">诊断证明书-->
<!--                <el-image-->
<!--                  class="patient-info__btn&#45;&#45;img"-->
<!--                  :src="transIcon"-->
<!--                  :preview-src-list="srcList">-->
<!--                </el-image>-->
<!--              </el-button>-->
              <el-button size="mini" class="patient-info__btn" type="primary" @click="implantationFlag = true">植入信息</el-button>
            </div>
          </div>
        </comp-part>
        <comp-part title="随访信息" :margin-bottom="40">
          <div class="part part-2">
            <el-table :data="followupInfo">
              <el-table-column
                align="center"
                label="序号"
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                align="center"
                prop="formName"
                label="表单名称">
              </el-table-column>
              <el-table-column
                align="center"
                width="140"
                label="操作">
                <template>
                  <el-button type="text" @click="infoFlag = true">填写</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </comp-part>
        <comp-part title="上传文件" :margin-bottom="40">
          <div class="part part-3">
            <!--        <el-button type="primary" class="upload-btn" size="mini" disabled>上传</el-button>-->
            <el-upload
              :disabled="uploadDisable"
              class="upload-btn"
              accept=".pdf,.PDF,.jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
              :show-file-list="false"
              multiple
              action=""
              :http-request="uploadReq"
              :limit="10"
            >
              <el-button size="mini" type="primary" :disabled="uploadDisable">上传文件</el-button>
            </el-upload>
            <!--        <el-button size="mini" class="upload-btn" type="primary">上传文件</el-button>-->
            <el-table :data="uploadInfo">
              <el-table-column
                align="center"
                label="序号"
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                align="center"
                prop="filename"
                label="文件名称">
              </el-table-column>
              <el-table-column
                align="center"
                prop="sizeFormat"
                label="大小">
              </el-table-column>
              <el-table-column
                align="center"
                prop="fileFormat"
                label="格式">
              </el-table-column>
              <el-table-column
                align="center"
                prop="status"
                label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.status === 0">上传中...</span>
                  <span v-if="scope.row.status === 1">上传完成</span>
                  <span v-if="scope.row.status === 2">上传失败</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                width="140"
                label="操作">
                <template slot-scope="scope">
<!--                  <el-button type="text" :disabled="!scope.row.path || scope.row.path === ''">查看</el-button>-->
                  <el-button v-if="scope.row.status !== 0" type="text" style="color: #f64343" @click="delUploadFile(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </comp-part>
        <comp-part title="建议事项" :margin-bottom="80">
          <div class="part part-1">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入结论"
              v-model="remark"
              maxlength="100"
              show-word-limit
            >
            </el-input>
          </div>
        </comp-part>
      </el-scrollbar>
    </el-card>
    <div class="form-btn">
      <el-button type="primary" @click="confirmPlan">完成本次随访</el-button>
      <el-button @click="goBack">返回</el-button>
    </div>
    <comp-info-dialog @sure="handleSure" :visible.sync="infoFlag"></comp-info-dialog>
    <comp-implant-dialog :plan-id="planId" :period-id="periodId" :patientId="patientId" :visible.sync="implantationFlag"></comp-implant-dialog>
  </div>
</template>

<script>
import CompPart from './components/Part'
import CompImplantDialog from './components/implantDialog'
import CompInfoDialog from './components/infoDialog'
import { ossInit } from '@/libs/utils/ali-oss-sdk'
import { cloneDeep } from 'lodash'
import { getFollowUpDetail, submitFollowUpInfo } from '@/service/module/followup'
import moment from 'moment'

export default {
  components: {
    CompPart,
    CompInfoDialog,
    CompImplantDialog
  },
  created () {
    ossInit()
  },
  mounted () {
    console.log('params:', this.$route)
    this.userInfo = cloneDeep(this.$route.params)
    const { periodId, planId } = this.$route.query
    console.log(periodId)
    console.log(planId)
    this.planId = planId
    this.periodId = periodId
    this.getBaseInfo(planId, periodId)
  },
  data () {
    return {
      planId: 0,
      periodId: 0,
      patientId: 0,
      userInfo: {},
      implantationFlag: false,
      infoFlag: false,
      followupInfo: [
        {
          formName: '随访信息'
        }
      ],
      content: {
        // 1、植入日期
        implantDate: '',
        // 2、电池状态
        batteryStatus: {
          status: -1,
          expectedLife: ''
        },
        // 3、起搏器参数
        pacemakerQuery: {
          checkedPacemakerModel: false,
          pacemakerModel: 0,
          checkedBareLineHeartRate: false,
          bareLineHeartRate: '',
          checkedAtrialPacingPercent: false,
          atrialPacingPercent: '',
          checkedATAF: false,
          atAf: ''
        },
        // 4、导线
        wireParam: {
          checkedAtrial: false,
          checkedLeftVentricular: false,
          checkedRightVentricular: false,
          atrialWire: {},
          // 心房导线参数
          atrialPacingThresholdV: '',
          atrialPacingThresholdMs: '',
          atrialPerception: '',
          atrialImpedance: '',
          // 右心室导线参数
          rightPacingThresholdV: '',
          rightPacingThresholdMs: '',
          rightPerception: '',
          rightHighVoltageImpedance: '',
          rightImpedance: '',
          // 左心室导线参数
          leftPacingThresholdV: '',
          leftPacingThresholdMs: '',
          leftPerception: '',
          leftImpedance: ''
        },

        // 5、不良事件
        adverseEvent: {
          hasEvent: 0,
          events: [],
          otherEvent: ''
        }
      },
      uploadInfo: [],
      transIcon: require('../../../assets/image/transparent.png'),
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      uploadDisable: false,
      remark: '',
      submitFlag: true
    }
  },
  methods: {
    async getBaseInfo (planId, periodId) {
      const [err, res] = await this.$to(getFollowUpDetail(
        {
          planId,
          periodId
        }
      ))
      if (res) {
        console.log('resData:', res.data)
        this.userInfo = res.data
        this.patientId = res.data.patientId
      }
      if (err) {
        throw Error(err.message)
      }
    },
    async uploadReq (file) {
      this.uploadDisable = true
      const filename = new Date().getTime() + '-' + file.file.name
      const name = file.file.name.substring(0, file.file.name.lastIndexOf('.'))
      const size = this.conver(file.file.size)
      const fileType = this.getFileType(file.file.name)
      const uploadItem = {
        filename: name,
        size: file.file.size,
        sizeFormat: size,
        fileFormat: fileType,
        status: 0,
        bucketName: process.env.NODE_ENV === 'production' ? 'fu-period-attachment' : 'fu-period-attachment-test'
      }
      this.uploadInfo.push(uploadItem)
      const length = this.uploadInfo.length
      const OSS = this.$store.getters['Oss/getOss']

      // .jpg,.JPG,.png,.PNG,.jpeg,.JPEG
      let headers
      switch (fileType) {
        case 'pdf' || 'PDF' : headers = {}
          break
        default: headers = { 'Content-Type': 'image/jpg' }
          break
      }

      try {
        const result = await OSS.put(`fu-files/${filename}`, file.file, { headers })
        console.log('result:', result)
        if (result) {
          this.uploadInfo[length - 1].status = 1
          this.uploadInfo[length - 1].path = `fu-files/${filename}`
        } else {
          this.uploadInfo[length - 1].status = 2
        }
        this.uploadDisable = false
      } catch (e) {
        console.log('上传错误：', e)
        this.uploadInfo[length - 1].status = 2
        this.uploadDisable = false
      }
    },
    getFileType (val) {
      return val.substring(val.lastIndexOf('.') + 1)
    },
    async confirmPlan () {
      if (!this.submitFlag) {
        return false
      }
      this.submitFlag = false
      console.log(this.uploadInfo)
      const params = {
        patientId: this.userInfo.patientId,
        type: (this.userInfo.periodStatus === 0 || this.userInfo.periodStatus === 2) ? 1 : 0,
        remark: this.remark,
        recordFiles: this.uploadInfo
      }
      const forms = [
        {
          type: 0,
          content: this.content
        }
      ]
      Reflect.set(params, 'forms', forms)
      if (this.userInfo.periodStatus !== 0 && this.userInfo.periodId !== 2) {
        Reflect.set(params, 'periodId', this.periodId)
      }
      try {
        const res = await submitFollowUpInfo(params)
        console.log('提交计划：', res)
        if (res.code === 200) {
          this.$message({
            message: '本次随访保存成功',
            type: 'success'
          })
          this.$router.replace({
            path: '/followupManage'
          })
        } else {
          this.$message({
            message: '系统错误',
            type: 'error'
          })
        }
        this.submitFlag = true
      } catch (e) {
        console.log(e)
        this.$message({
          message: '系统错误',
          type: 'error'
        })
        this.submitFlag = true
      }
    },
    goBack () {
      this.$confirm('当前随访尚未完成，返回将清空数据', '返回提示', {
        confirmButtonText: '确认返回',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$router.replace({
          path: '/followupManage'
        })
      }).catch(() => {})
    },
    delUploadFile (index) {
      this.uploadInfo.splice(index, 1)
    },
    handleSure (data) {
      console.log('data:', data)
      this.content = data
    },
    conver (limit) {
      var size = ''
      if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else { // 其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }

      var sizestr = size + ''
      var len = sizestr.indexOf('.')
      var dec = sizestr.substr(len + 1, 2)
      if (dec === '00') { // 当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
      }
      return sizestr
    },
    formatDate (date) {
      if (date) {
        return moment.unix(date / 1000).format('Y-MM-DD')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.followup-detail {
  min-width: 1080px;
  .detail-card {
    width: 100%;
    margin: 0 auto 3vh;
    height:75vh;
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .part {
    display: flex;
    justify-content: space-between;
    /*width: 80%;*/
    .part-item {
      display: flex;
      align-items: center;
      .patient-info__btn {
        width: 112px;
        position: relative;
        .patient-info__btn--img {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 999;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .part-1 {}
  .part-2 {
    width: 500px;
  }
  .part-3 {
    display: inherit;
    .upload-btn {
      margin-bottom: 20px;
    }
    .upload-hide {
      visibility: hidden;
    }
  }
  ::v-deep .el-card__body {
    height: 100%;
  }
}
</style>
