<template>
  <div class="part-box" :style="`margin-bottom: ${marginBottom}px`">
    <div class="part-header">
      {{title}}
    </div>
    <div class="part-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    marginBottom: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style scoped lang="scss">
.part-box {
  .part-header {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    &::before {
      content: ' ';
      display: inline-block;
      width: 4px;
      height: 16px;
      background-color: #00a2ff;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
  .part-content {
    padding-left: 14px;
    padding-top: 10px;
  }
}
</style>
