<template>
  <el-dialog
    title="植入信息"
    width="800px"
    height="500px"
    :visible="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="true"
    @close="cancel"
  >
    <div class="content">
      <el-scrollbar ref="wrapper">
        <div class="content-part">
          <div class="content-title">
            <div class="label">1、CIED植入信息</div>
          </div>
          <div class="content-form">
            <el-form :rules="requireItem" ref="requireItem" style="margin-top: 15px" :model="implantInfo" label-width="80px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="植入日期" prop="implantDate">
                    <el-date-picker
                      v-model="implantInfo.implantDate"
                      class="fix-form__width"
                      type="date"
                      :disabled="true"
                      format="yyyy-MM-dd"
                      value-format="timestamp"
                      :picker-options="pickerOptions"
                      placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="植入医院">
                    <el-input v-model="implantInfo.hospitalName" class="fix-form__width"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="术者" prop="operator">
                    <el-input v-model="implantInfo.operator" mixlength="2" maxlength="20" placeholder="请输入术者姓名" class="fix-form__width"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <!--    植入部位   -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">2、CIED植入部位</div>
          </div>
          <div class="content-form">
            <el-form style="margin-top: 15px">
              <el-form-item>
                <el-radio-group v-model="implantPosition">
                  <el-radio :label="1">左侧</el-radio>
                  <el-radio :label="2">右侧</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    适应证    -->
        <div class="content-part part1">
          <div class="content-title">
            <div class="label">3、适应证</div>
          </div>
          <div class="content-form">
            <el-form :model="indications">
              <el-form-item>
                <el-checkbox-group v-model="indications.indicationsSymptom" @change="changeAdaptation">
                  <el-checkbox class="el-check-pinpai" :label="0">
                    心动过缓
                  </el-checkbox>
                  <el-checkbox-group v-if="indications.indicationsSymptom.indexOf(0) !== -1" v-model="indications.bradycardiaRadio" class="adaptation_content--1">
                    <el-checkbox :label="0">病态窦房结综合征</el-checkbox>
                    <el-checkbox :label="1">房室传导阻滞</el-checkbox>
                    <el-checkbox :label="2">房颤伴慢室率</el-checkbox>
                  </el-checkbox-group>
                  <el-checkbox class="el-check-pinpai" :label="1">
                    心力衰竭
                  </el-checkbox>
                  <br>
                  <el-checkbox class="el-check-pinpai" :label="2">
                    室性心律失常
                  </el-checkbox>
                  <el-radio-group v-if="indications.indicationsSymptom.indexOf(2) !== -1" v-model="indications.arrhythmiaRadio" class="adaptation_content--1">
                    <el-radio :label="0">室性心动过速</el-radio>
                    <el-radio :label="1">心室颤动</el-radio>
                    <el-radio :label="2">室性期前收缩</el-radio>
                  </el-radio-group>
                  <el-checkbox :label="3">
                    其他
                  </el-checkbox>
                  <el-input
                    :disabled="indications.indicationsSymptom.indexOf(3) === -1"
                    v-model="indications.otherIndications"
                    placeholder="请输入其他适应证"
                    size="small"
                    style="display: block; width: 288px;"
                    maxlength="20">
                  </el-input>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    CIED类型  -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">4、CIED类型</div>
          </div>
          <div class="content-form">
            <el-form  style="margin-top: 15px" :model="ciedType">
              <el-form-item>
                <el-radio-group v-model="ciedType.ciedTypeRadio" @change="changeCiedType">
                  <el-radio :label="0">单腔起搏器</el-radio>
                  <el-radio :label="1">双腔起搏器</el-radio>
                  <el-radio :label="2">单腔ICD</el-radio>
                  <el-radio :label="3">双腔ICD</el-radio>
                  <el-radio :label="4">CRT-P</el-radio>
                  <el-radio :label="5">CRT-D</el-radio>
                  <el-radio :label="6">其他</el-radio>
                  <el-input :disabled="ciedType.ciedTypeRadio !== 6" v-model="ciedType.otherCiedType" maxlength="20" placeholder="请输入其他CIED类型" size="small" style="display: inline-block; width: 228px"></el-input>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    品牌      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">5、CIED品牌</div>
          </div>
          <div class="content-form">
            <el-form  style="margin-top: 15px" :model="ciedBrand">
              <el-form-item>
                <el-radio-group class="ciedBrand-radio-group" v-model="ciedBrand.key" @change="changeCiedBrand">
                  <el-radio :label="0">美敦力
                    <el-select :disabled="ciedBrand.key !== 0" filterable class="el-checkbox-input" allow-create v-model="ciedBrand.model" placeholder="请输入">
                      <el-option
                        v-for="i in ciedBrandSelect[0].children"
                        :key="i.code"
                        :label="i.title"
                        :value="i.code">
                      </el-option>
                    </el-select>
                  </el-radio>
                  <br>
                  <el-radio :label="1">雅培&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-input
                      v-model="ciedBrand.yapeitext"
                      :disabled="ciedBrand.key !== 1"
                      class="el-checkbox-input"
                      placeholder="请输入型号"
                      maxlength="20">
                    </el-input>
                  </el-radio>
                  <br>
                  <el-radio :label="2">百力多
                    <el-input
                      v-model="ciedBrand.bailiduotext"
                      :disabled="ciedBrand.key !== 2"
                      class="el-checkbox-input"
                      placeholder="请输入型号"
                      maxlength="20">
                    </el-input>
                  </el-radio>
                  <br>
                  <el-radio :label="3">波科&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-input
                      v-model="ciedBrand.boketext"
                      :disabled="ciedBrand.key !== 3"
                      class="el-checkbox-input"
                      placeholder="请输入型号"
                      maxlength="20">
                    </el-input>
                  </el-radio>
                  <br>
                  <el-radio :label="4">其他&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-input
                      v-model="ciedBrand.qitatext"
                      :disabled="ciedBrand.key !== 4"
                      class="el-checkbox-input"
                      placeholder="输入其他CIED品牌"
                      maxlength="20">
                    </el-input>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    导线      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">6、导线</div>
          </div>
          <div class="content-form">
            <el-form :model="wireParam">
              <el-form-item>
                <el-checkbox v-model="wireParam.checkedAtrial" class="el-check-pinpai" label="心房导线" @change="changeLead"></el-checkbox>
                <div class="lead-atrial" v-if="wireParam.checkedAtrial">
                  <div class="item-title">
                    植入部位:
                  </div>
                  <el-radio-group v-model="wireParam.atrialImplantPosition">
                    <el-radio :label="0">右心耳</el-radio>
                    <el-radio :label="1">右心房侧壁</el-radio>
                  </el-radio-group>
                  <br>
                  <div class="item-title">导线型号:</div>
                  <el-input size="small" placeholder="请输入导线型号" v-model="wireParam.atrialWireModel"></el-input>
                  <br>
                  <div class="item-title">
                    起搏阈值:
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                    </el-col>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdMs"></el-input>&nbsp;ms
                    </el-col>
                  </el-row>
                  <div class="item-title">
                    P波高度:
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialPWaveAltitude"></el-input>&nbsp;mV&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">
                    阻抗:
                  </div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.atrialImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                </div>
                <el-checkbox v-model="wireParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
                <div class="lead-right" v-if="wireParam.checkedRightVentricular">
                <div class="item-title">植入部位:</div>
                  <el-radio-group v-model="wireParam.rightImplantPosition">
                    <el-radio :label="0">右室心尖部</el-radio>
                    <el-radio :label="1">右室间隔部</el-radio>
                    <el-radio :label="2">希氏束区域</el-radio>
                    <el-radio :label="3">左束支区域</el-radio>
                  </el-radio-group>
                <div class="item-title">导线型号:</div>
                <el-input size="small" placeholder="请输入导线型号" v-model="wireParam.rightWireModel"></el-input>
                <div class="item-title">起搏阈值:</div>
                <el-row>
                  <el-col :span="12">
                    <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                  </el-col>
                  <el-col :span="12">
                    <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                  </el-col>
                </el-row>
                <div class="item-title">R波高度:</div>
                <el-row>
                  <el-col :span="12">
                    <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightRWaveAltitude"></el-input>&nbsp;mV&nbsp;
                  </el-col>
                </el-row>
                <div class="item-title">阻抗:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                <div class="item-title">高压除颤阻抗:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.rightHighVoltageImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                </div>
                <el-checkbox v-model="wireParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
                <div class="lead-left" v-if="wireParam.checkedLeftVentricular">
                  <div class="item-title">植入部位:</div>
                  <el-radio-group v-model="wireParam.leftImplantPosition" @change="changeLeadSite">
                    <el-radio :label="0">侧后静脉</el-radio>
                    <el-radio :label="1">侧静脉</el-radio>
                    <el-radio :label="2">其他</el-radio>
                    <el-input
                      class="el-checkbox-input-left"
                      size="mini"
                      placeholder="请输入内容"
                      v-show="wireParam.leftImplantPosition === 2"
                      v-model="wireParam.leftOtherPosition"
                      minlength="2"
                      maxlength="20">
                    </el-input>
                  </el-radio-group>
                  <div class="item-title">导线型号:</div>
                  <el-input size="small" placeholder="请输入导线型号" v-model="wireParam.leftWireModel"></el-input>
                  <div class="item-title">起搏阈值:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdV"></el-input>&nbsp;V@&nbsp;
                    </el-col>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftPacingThresholdMs"></el-input>&nbsp;ms&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">R波高度:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftRWaveAltitude"></el-input>&nbsp;mV&nbsp;
                    </el-col>
                  </el-row>
                  <div class="item-title">阻抗:</div>
                  <el-row>
                    <el-col :span="12">
                      <el-input size="small" style="width: 85%;" placeholder="请输入内容" v-model="wireParam.leftImpedance"></el-input>&nbsp;Ω&nbsp;
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    起搏器参数      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">7、起搏器参数设置</div>
          </div>
          <div class="content-form">
            <el-form :model="pacemakerQuery">
              <el-form-item>
<!--                <el-checkbox v-model="pacemakerQuery.checkedPacemakerModel" style="display: block" label="起搏模式" @change="changeQuery"></el-checkbox>-->
                <div>起搏模式</div>
                <el-radio-group v-model="pacemakerQuery.pacemakerModel" class="subPart adaptation_content--1">
                  <el-radio :label="1">AAI(R)</el-radio>
                  <el-radio :label="2">VVI(R)</el-radio>
                  <el-radio :label="3">DDD(R)</el-radio>
                </el-radio-group>
<!--                <el-checkbox v-model="pacemakerQuery.checkedBareLineHeartRate" label="基线频率" style="margin-right: 20px" @change="changeQuery"></el-checkbox>-->
                <div>基线频率</div>
                <div>
                  <el-input size="small" v-model="pacemakerQuery.bareLineHeartRate" style="width: 350px;" placeholder="请输入内容"></el-input>&nbsp;bpm
                </div>
                <span >
                  </span>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--    不良事件      -->
        <div class="content-part">
          <div class="content-title">
            <div class="label">8、围术期不良事件</div>
          </div>
          <div class="content-form">
            <el-form  style="margin-top: 15px" :model="adverseEvent">
              <el-form-item style="margin-bottom: 0">
                <el-radio-group v-model="adverseEvent.hasEvent" @change="changeEvent">
                  <el-radio :label="0">无</el-radio>
                  <br>
                  <el-radio :label="1">有</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="adverseEvent.hasEvent === 1">
                <el-checkbox-group v-model="adverseEvent.events" class="adaptation_content--1 events" @change="changeEventCheckBox">
                  <el-checkbox :label="1" name="type">气胸</el-checkbox>
                  <el-checkbox :label="2" name="type">血胸</el-checkbox>
                  <el-checkbox :label="3" name="type">血气胸</el-checkbox>
                  <el-checkbox :label="4" name="type">导线穿孔</el-checkbox>
                  <el-checkbox :label="5" name="type">导线脱落</el-checkbox>
                  <el-checkbox :label="6" name="type">感染</el-checkbox>
                  <el-checkbox :label="7" name="type">导线重置</el-checkbox>
                  <el-checkbox :label="8" name="type">血肿</el-checkbox>
                  <el-checkbox :label="9" name="type">其他</el-checkbox>
                  <el-input
                    v-if="adverseEvent.events.indexOf(9) !== -1"
                    size="small"
                    v-model="adverseEvent.otherEvent"
                    style="width: 150px; margin-left: 10px"
                    maxlength="20"
                    placeholder="请输入其他不良事件"></el-input>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { cloneDeep } from 'lodash'
import { saveImplant, getImplant } from '@/service/module/followup'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    planId: [Number, String],
    periodId: [Number, String],
    patientId: [Number, String]
  },
  data () {
    return {
      requireItem: {
        implantDate: [
          { required: true, message: '请选择植入日期', trigger: 'blur' }
        ],
        operator: [
          { required: true, message: '请输⼊术者', trigger: 'blur' }
        ]
      },
      // 1、植入信息
      implantInfo: {
        implantDate: '',
        operator: '',
        hospitalName: ''
      },
      // 2、植入部位
      implantPosition: -1,
      // 3、适应证
      indications: {
        indicationsSymptom: [],
        bradycardiaRadio: [],
        otherIndications: '',
        arrhythmiaRadio: null
      },
      // 4、类型
      ciedType: {
        ciedTypeRadio: -1,
        otherCiedType: ''
      },
      // 5、品牌
      ciedBrand: {
        yapeitext: '',
        bailiduotext: '',
        boketext: '',
        qitatext: '',
        model: null,
        key: null
      },
      ciedBrandSelect: [ // 美敦力设备列表
        { children: [] }
      ],
      // 6、导线
      wireParam: {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        // 心房导线参数
        atrialImplantPosition: -1,
        atrialWireModel: '',
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPWaveAltitude: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightImplantPosition: -1,
        rightWireModel: '',
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightRWaveAltitude: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftOtherPosition: '',
        leftImplantPosition: -1,
        leftWireModel: '',
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftRWaveAltitude: '',
        leftImpedance: ''
      },
      // 7、起搏器参数
      pacemakerQuery: {
        checkedPacemakerModel: false,
        bareLineHeartRate: '',
        checkedBareLineHeartRate: false,
        pacemakerModel: 0
      },
      // 8、不良事件
      adverseEvent: {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      id: null
    }
  },
  watch: {
    visible: {
      handler (newVal) {
        console.log('visible:', newVal)
        if (newVal) {
          this.getImplantInfo(this.patientId)
        }
      },
      immediate: true
    }
  },
  methods: {
    async getImplantInfo (patientId) {
      try {
        const res = await getImplant(patientId)
        /**
         * 初始值判定赋值
         * */
        this.ciedBrandSelect = res.data.ciedBrandSelect
        if (res.data.ciedBrand && typeof (res.data.ciedBrand.model) === 'string') {
          const obj = {
            code: 0,
            title: this.ruleForm.ciedBrand.model
          }
          this.ciedBrandSelect.push(obj)
        }
        this.implantInfo = {
          implantDate: res.data.implantDate,
          operator: res.data.operator,
          hospitalName: res.data.hospitalName
        }
        if (res.data.ciedKind) {
          this.ciedType = res.data.ciedKind
        }
        if (res.data.pacemakerParam) {
          this.pacemakerQuery = res.data.pacemakerParam
        }
        if (res.data.leadParam) {
          this.wireParam = res.data.leadParam
        }
        if (res.data.adverseEvent) {
          this.adverseEvent = res.data.adverseEvent
        }
        if (res.data.adaptationDisease) {
          this.indications = res.data.adaptationDisease
          if (typeof this.indications.bradycardiaRadio === 'number') {
            this.indications.bradycardiaRadio = [this.indications.bradycardiaRadio]
          }
          console.log('this.indications:', this.indications)
          console.log('this.indications:', typeof this.indications.bradycardiaRadio)
        }
        if (res.data.ciedBrand) {
          this.ciedBrand = res.data.ciedBrand
        }
        if (res.data.ciedImplantSite) {
          this.implantPosition = res.data.ciedImplantSite
        }
        if (res.data.id) {
          this.id = res.data.id
        }

        console.log('植入信息:', res)
      } catch (e) {
        console.log('err;', e)
      }
    },
    async confirm () {
      this.$refs.requireItem.validate(async valid => {
        console.log(valid)
        if (valid) {
          this.$confirm('是否确认保存植入信息?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(async () => {
            try {
              const params = {
                id: this.id,
                ...this.implantInfo,
                adaptationDisease: this.indications,
                ciedBrand: this.ciedBrand,
                leadParam: this.wireParam,
                ciedImplantSite: this.implantPosition,
                ciedKind: this.ciedType,
                pacemakerParam: this.pacemakerQuery,
                adverseEvent: this.adverseEvent
              }
              console.log(params)
              const res = await saveImplant(params)
              console.log('!!!!', res)
              if (res.code === 200) {
                this.$message({
                  message: '植入信息保存成功',
                  type: 'success'
                })
                this.$emit('update:visible', false)
              } else {
                this.$message({
                  message: '保存失败',
                  type: 'error'
                })
              }
            } catch (e) {
              this.$message({
                message: '系统错误',
                type: 'error'
              })
            }
          })
        }
      })
    },
    cancel () {
      this.$refs.requireItem.clearValidate()
      this.$emit('update:visible', false)
    },
    changeCiedBrand (val) {
      this.ciedBrand.key = val
      switch (val) {
        case 0:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          break
        case 1:
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 2:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 3:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.qitatext = ''
          this.ciedBrand.model = null
          break
        case 4:
          this.ciedBrand.yapeitext = ''
          this.ciedBrand.boketext = ''
          this.ciedBrand.bailiduotext = ''
          this.ciedBrand.model = null
          break
      }
    },
    changeCiedType (val) {
      if (val !== 6) {
        this.ciedType.otherCiedType = ''
      }
    },
    changeAdaptation (val) {
      if (val.indexOf(0) === -1) {
        this.indications.bradycardiaRadio = []
      }
      if (val.indexOf(3) === -1) {
        this.indications.otherIndications = ''
      }
      if (val.indexOf(2) === -1) {
        this.indications.arrhythmiaRadio = null
      }
    },
    changeLead () {
      if (!this.wireParam.checkedAtrial) {
        this.wireParam.atrialImplantPosition = -1
        this.wireParam.atrialWireModel = ''
        this.wireParam.atrialPacingThresholdV = ''
        this.wireParam.atrialPacingThresholdMs = ''
        this.wireParam.atrialPWaveAltitude = ''
        this.wireParam.atrialImpedance = ''
      }
      if (!this.wireParam.checkedRightVentricular) {
        this.wireParam.rightImplantPosition = -1
        this.wireParam.rightWireModel = ''
        this.wireParam.rightPacingThresholdV = ''
        this.wireParam.rightPacingThresholdMs = ''
        this.wireParam.rightRWaveAltitude = ''
        this.wireParam.rightHighVoltageImpedance = ''
        this.wireParam.rightImpedance = ''
      }
      if (!this.wireParam.checkedLeftVentricular) {
        this.wireParam.leftOtherPosition = ''
        this.wireParam.leftImplantPosition = -1
        this.wireParam.leftWireModel = ''
        this.wireParam.leftPacingThresholdV = ''
        this.wireParam.leftPacingThresholdMs = ''
        this.wireParam.leftRWaveAltitude = ''
        this.wireParam.leftImpedance = ''
      }
    },
    changeLeadSite (val) {
      if (val !== 2) {
        this.wireParam.leftOtherPosition = ''
      }
    },
    changeQuery () {
      if (!this.pacemakerQuery.checkedPacemakerModel) {
        this.pacemakerQuery.model = 0
      }
      if (!this.pacemakerQuery.checkedBareLineHeartRate) {
        this.pacemakerQuery.bareLineHeartRate = ''
      }
    },
    changeEvent () {
      if (this.adverseEvent.hasEvent !== 1) {
        this.adverseEvent.events = []
        this.adverseEvent.otherEvent = ''
      } else {
        this.$refs.wrapper.wrap.scrollTop = this.$refs.wrapper.wrap.scrollHeight
      }
    },
    changeEventCheckBox (val) {
      if (val.indexOf(9) === -1) {
        this.adverseEvent.otherEvent = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
    height: 400px;
    .content-part {
      .content-title {
        display: flex;
        align-items: center;
        .label {
          font-size: 16px;
          font-weight: bold;
          margin-right: 15px;
          width: 150px;
        }
      }
      .content-form {
        padding-left: 40px;
        .fix-form__width {
          width: 80%;
        }
        .el-radio {
          margin-bottom: 15px;
        }
        .el-checkbox-input {
          width: 430px;
        }
        .editor {
          text-align: center;
          display: inline-block;
          width: 90px;
          line-height: 1;
          border-bottom: 1px solid #000;
          font-size: 16px;
        }
        .el-checkbox-input-left {
          width: 180px;
          /*height: 10px;*/
          vertical-align: initial;
        }
        .el-check-pinpai {
          width: 100%;
        }
        .adaptation_content--1 {
          border: 1px solid #EBEEF5;
          display: flex;
          align-items: center;
          width: 450px;
          padding: 16px;
          ::v-deep .el-radio {
            margin-bottom: 0;
          }
        }
        .subPart {
          display: block;
          padding-left: 30px;
          width: 350px;
        }
        .events {
          width: 600px;
          display: flex;
          flex-wrap: wrap;
        }
        .lead-atrial {
          padding: 16px;
          width: 430px;
          border: 1px solid #EBEEF5;
        }
        .lead-right {
          padding: 16px;
          border: 1px solid #EBEEF5;
          width: 500px;
        }
        .lead-left {
          padding: 16px;
          border: 1px solid #EBEEF5;
          width: 510px;
        }
      }
    }
    .part1 {
      ::v-deep .el-radio {
        margin-bottom: 15px;
      }
      ::v-deep .el-radio-group {
        display: block;
        margin-left: 20px;
        font-size: inherit;
      }
    }
    ::v-deep .el-scrollbar .el-scrollbar__wrap{
      padding-bottom: 13px;
      max-height: 445px;
    }
}
</style>
<style lang="scss">
  .content {
    .el-scrollbar__view {
      overflow-x: hidden;
    }
  }
</style>
